<template>
    <div class="card border-radius">
        <div class="card-body">
            <div class="my-3 mb-5">
                <h1 class="faq-heading">Frequently asked questions</h1>
            <div class="faq-description mt-4">
                Everything you need to know about the product and billing.
            </div>
        </div>
        <div class="container">
            <div class="collapse-section" v-for="(faq,index) in faqList" :key="index">
            <div class="d-flex justify-content-between"  v-b-toggle="'collapse'+index">
                <div class="faq-title">{{ faq.question }}</div>
                <div class="faq-icon"> <div class="circle-minus">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#4D1B7E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div> <div class="circle-plus"><svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 10V18M8 14H16M22 14C22 19.5228 17.5228 24 12 24C6.47715 24 2 19.5228 2 14C2 8.47715 6.47715 4 12 4C17.5228 4 22 8.47715 22 14Z" stroke="#4D1B7E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div> </div>
            </div>
            <b-collapse :id="'collapse'+index">
        <div class="faq-answer mt-2">
            {{ faq.answer }}
        </div>
      </b-collapse>
        </div>
        </div>
    </div>

    </div>
</template>
<script>
export default{
data(){
    return {
        faqList:[
    {
        "question": "Can I customize the quizzes and forms without coding skills?",
        "answer": "Yes, Quizell features a no-code, drag-and-drop builder that allows you to easily create and customize quizzes and forms. This makes it simple for anyone to design engaging and personalized content without any technical expertise."
    },
    {
        "question": "Can Quizell recommend more than just products?",
        "answer": "Absolutely! Quizell's intelligent algorithm is designed to recommend services, tips, and more, tailored to your customers' needs."
    },
    {
        "question": "What insights can I gain about my shoppers with Quizell?",
        "answer": "Quizell provides comprehensive analytics, offering deep insights into shopper behavior and preferences, helping you make informed decisions."
    },
    {
        "question": "Which platforms does Quizell integrate with?",
        "answer": "Quizell is highly versatile; embed it into any website with HTML. We offer native integrations with Shopify, Wix, Mailchimp, Active Campaign, Mailerlite, Judge.me, Omnisend, Klaviyo, Google Analytics, and Hubspot. Plus, our Zapier app connects you to 10,000+ third-party apps for endless possibilities."
    },
    {
        "question": "Is Quizell just for quizzes?",
        "answer": "No, Quizell is much more! Use it for quizzes, product recommendations, forms, surveys, and various funnel types to engage and understand your audience better."
    },
    {
        "question": "How does Quizell manage privacy and zero-party data?",
        "answer": "Quizell is committed to the highest standards of data security and privacy. We adhere to CalOPPA, CCPA (CPRA), and GDPR guidelines to protect your information. As a zero-party data platform, Quizell enables you to collect data directly from your customers, ensuring transparency and trust in every interaction. Your and your customers' privacy is our top priority."
    },
    {
        "question": "What if I change my mind and want to cancel?",
        "answer": "We understand that needs can change. If you decide Quizell isn't the right fit for you, canceling your plan is straightforward. You can manage your subscription settings directly in your account or contact our support team for assistance. We're here to help make the transition smooth and hassle-free."
    }
]
    }
}
}
</script>
<style scoped>
.faq-description{
    color:  #667085;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}
.faq-heading{
    color: #292D35;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 48px; /* 100% */
}
.faq-title{
font-size: 18px;
font-weight: 500;
line-height: 26px;
text-align: left;
color: #292D35;

}

.faq-answer{
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #667085;

}
.collapse-section{
    border-top:1px solid #EAECF0;
    padding: 24px 0px;
}
.collapsed > .faq-icon > .circle-minus, :not(.collapsed)> .faq-icon > .circle-plus {
    display: none !important;
}
</style>